<template>
  <v-dialog data-app v-model="opened" max-width="600px" @input="onToggle">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Информация по звонку</v-card-title>
      <v-card-text>
        <v-form ref="balanceForm">
          <ClientLink v-if="item.client && item.client.n_id" :value="item.client"/>
          <div v-else>{{ item.phone }}</div>
          <v-row class="row-d-block">
            <v-subheader>Статус</v-subheader>
            <v-select v-model="item.status" outlined
                      :items="[{text:'Не указано', value: 0},{text:'Ожидание', value: 2},{text:'Отказ', value: 3},{text:'Нецелевой', value: 4}]"></v-select>
          </v-row>
          <v-row class="row-d-block" v-if="item.status===3">
            <v-subheader>Причина отказа</v-subheader>
            <v-select v-model="item.status2" outlined
                      :items="$store.state.leadStatuses2"></v-select>
          </v-row>
          <v-row class="row-d-block" v-if="item.status===4">
            <v-subheader>Тип</v-subheader>
            <v-select v-model="item.status3" outlined
                      :items="$store.state.toSelectItems($store.state.leadInappropriateType)"></v-select>
          </v-row>
          <template v-if="![3,4].includes(item.status)">
            <v-row class="row-d-block">
              <v-subheader>Расчет стоимости</v-subheader>
              <v-checkbox v-model="item.calculation"></v-checkbox>
              <v-textarea v-if="item.calculation"
                          placeholder="СМС клиенту с расчетом"
                          v-model="item.sms"
                          outlined
                          rows="10"/>
              <v-btn v-if="item.calculation" class="mt-2" small
                     @click="$eventBus.$emit('sendSMS', item.client.id ? item.client: {phone: item.phone, name: ''}, item.sms, ()=>onCalculationSent())">
                Отправить смс
              </v-btn>
            </v-row>
            <v-row v-if="item.calculation" class="row-d-block">
              <v-subheader>Расчет отправлен</v-subheader>
              <v-checkbox v-model="item.calculationSent"></v-checkbox>
            </v-row>
            <v-row class="row-d-block">
              <v-subheader>Дата след контакта</v-subheader>
              <v-menu
                  ref="menu"
                  v-model="date_opened"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      :value="$root.dateToRus(item.next_date)"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  />
                </template>
                <v-date-picker
                    v-model="item.next_date"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    locale="ru"
                    :reactive="true"
                    @change="date_opened = false"
                />
              </v-menu>
            </v-row>
          </template>
          <v-row class="row-d-block">
            <v-subheader>Комментарий</v-subheader>
            <v-textarea
                v-model="item.comment"
                outlined
                rows="4"/>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading" @click="save()">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ClientLink from "../../components/ClientLink";

export default {
  name: "LeadEdit",
  components: {ClientLink},
  data() {
    return {
      loading: false,
      date_opened: false,
      opened: false,
      item: {}
    }
  },
  methods: {
    open(item) {
      this.opened = true;
      this.item = item
      console.log(item);
    },
    onToggle() {
      this.sum = null;
      this.comment = null;
    },
    getParams() {
      return {
        params: {
          comment: this.item.comment,
          status: this.item.status,
          status2: this.item.status2,
          status3: this.item.status3,
          calculationSent: this.item.calculationSent,
          calculation: this.item.calculation,
          sms: this.item.sms,
          next_date: this.item.next_date,
        },
        id: this.item.leads[0].item.id,
      };
    },
    onCalculationSent() {
      this.item.calculationSent = true;
      this.save(null);
    },
    save(onDone = () => this.opened = false) {
      this.loading = true;
      this.$store.state.server.request('lead/update', this.getParams(), () => {
        this.$root.notify('Сохранено');
        this.$eventBus.$emit('leadSaved')
        this.loading = false
        if (onDone) onDone();
      }, (data) => {
        if (data && data.error) this.$root.notify(data.error, 'error')
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>

</style>