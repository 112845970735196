<template>
  <v-dialog data-app v-model="opened" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>{{ add ? 'Начисление' : 'Списание' }} бонусов</v-card-title>
      <v-card-text>
        <v-form
          ref="form">
          <v-row class="mb-4 row-d-block">
            <v-subheader>Сумма</v-subheader>
            <v-text-field
              v-model="sum"
              outlined></v-text-field>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Причина {{ add ? 'начисления' : 'списания' }}</v-subheader>
            <v-textarea
              v-model="comment"
              outlined
              rows="4"
              placeholder="Комментарий"></v-textarea>
          </v-row>
          <v-row v-if="error">
            <v-subheader style="color: red">{{ error }}</v-subheader>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading" @click="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddClientBonus",
  data() {
    return {
      opened: false,
      loading: false,
      id: null,
      sum: null,
      add: true,
      comment: '',
      error: null,
    }
  },
  methods: {
    open(client_id, add) {
      this.client_id = client_id;
      this.add = add;
      this.opened = true;
      this.sum = '';
    },
    save() {
      this.loading = true;
      this.$store.state.server.request('client/addBonus/' + this.client_id, {
        sum: this.sum * (this.add ? 1 : -1),
        comment: this.comment
      }, (data) => {
        this.loading = false;
        this.$eventBus.$emit('clientBonusChanged', data.response)
        this.$root.notify('Бонус ' + this.$root.printCost(this.sum) + (this.add ? ' начислен' : ' списан'), 'success')
        this.opened = false;
      }, (data) => {
        this.error = data.error;
      })
    }
  }
}
</script>

<style scoped>

</style>