<template>
  <span>
  <v-list-item-content style="max-width: 300px;padding: 15px;border-radius: 5px 5px 0 0;"
                       :style="!parseInt(notification.datetime_seen) && !notification.hidden ? 'background: #fff6c8;': ''">
    <v-list-item-subtitle>
      {{ $root.dateToRus(notification.datetimeObj.date) }}
      {{ notification.datetimeObj.time }}
    </v-list-item-subtitle>
    <v-list-item-title>{{ notification.title }}</v-list-item-title>
    <template v-if="notification.type==='crm_task'">
      <v-list-item-content>
        {{ notification.data.title }}
        <span style="margin: 10px 0" v-if="notification.data.text">{{ notification.data.text }}</span>
        Срок выполнения: {{
          notification.data.datetime_toObj.time
        }} {{ $root.dateToRus(notification.data.datetime_toObj.date) }}
      </v-list-item-content>
    </template>
    <v-list-item-subtitle style="white-space: inherit;">
      <template v-if="notification.data.client">
        <ClientLink v-if="parseInt(notification.data.client.n_id)" :value="notification.data.client"></ClientLink>
        <div v-else>{{ notification.data.client.name }} {{ notification.data.client.phone }}</div>
      </template>
      <template v-if="notification.data.cleaner">
        <CleanerLink :value="notification.data.cleaner"></CleanerLink>
        {{ notification.data.cleaner.phone }}
      </template>
      <template v-if="notification.data.order">
        <OrderLink :value="notification.data.order"/>
      </template>
      <template v-if="notification.data.diff_text">
        <span v-html="notification.data.diff_text.replaceAll('\n', '<br>')"></span>
      </template>
    </v-list-item-subtitle>
    <v-list-item-action style="flex-direction: row;margin: 0;">
      <v-btn style="margin-top: 10px" small v-if="!parseInt(notification.datetime_seen)"
             v-on:click.stop="$store.commit('setSeen', [notification]);">
        Ок
      </v-btn>
        <v-btn v-if="!['crm_task'].includes(notification.type) && notification.data.client" small
               @click="$store.commit('setSeen', [notification]);$router.push('/create/'+notification.data.client.phone)"
               role="link">
          Создать заказ
        </v-btn>
    </v-list-item-action>
  </v-list-item-content>
  <div v-if="!parseInt(notification.datetime_seen) && !notification.hidden" style="width: 100%;background: #ffe7b1;">
    <div class="notification_progress_timer"
         :style="`animation: progress_timer ${notification.timeout}s linear;`"></div>
  </div>
    </span>
</template>
<script>
import CleanerLink from "../../components/CleanerLink";
import ClientLink from "../../components/ClientLink";
import OrderLink from "../../components/OrderLink";

export default {
  name: 'Notification',
  components: {OrderLink, ClientLink, CleanerLink},
  props: {
    notification: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
    }
  },
}
</script>
