<template>
  <v-dialog data-app v-model="opened" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened = false;" size="36">mdi-close</v-icon>
      <v-card-title v-text="title" style="word-break: break-word"/>
      <v-card-text class="px-0">
        <v-simple-table>
          <thead>
          <tr>
            <th>№</th>
            <th>Клинер</th>
            <th>{{ dateTitle }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(cleaner, index) in cleaners">
            <td>{{ index + 1 }}</td>
            <td>
              <CleanerLink full-name :value="cleaner"/>
            </td>
            <td>{{ $root.dateToRus(cleanerDate(cleaner)) }}</td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CleanerLink from "../../components/CleanerLink";

export default {
  name: "CleanersList",
  components: {CleanerLink},
  data() {
    return {
      opened: false,
      title: 'Клинеры',
      dateTitle: '',
      cleanerDate: null,
      cleaners: [],
    }
  },
  methods: {
    open(cleaners, title = '', cleanerDate = null) {
      this.title = '';
      this.cleanerDate = cleanerDate || ((cleaner) => cleaner.datetime_createObj.date);
      this.dateTitle = title || 'Добавлен в CRM';
      this.cleaners = cleaners;
      this.opened = true;
    },
  }
}
</script>
