<template>
  <v-dialog data-app @input="onToggle()" v-model="opened" :retain-focus="false">
    <v-card style="margin: 0; height: fit-content">
      <v-icon class="btn-close-modal" @click="close()" size="36">mdi-close</v-icon>
      <v-card-title>
        {{ task.id ? 'Редактирование задачи' : 'Создание задачи' }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="col-6 col-md-2">
            <v-row class="row-d-block">
              <v-subheader>Выполнено</v-subheader>
              <v-checkbox
                  v-model="task.status"
                  true-value="1"
                  @change="setDone"
                  false-value="0"
              />
            </v-row>
          </v-col>
          <v-col class="col-6 col-md-2">
            <v-row class="row-d-block">
              <v-subheader>Приоритет</v-subheader>
              <v-select
                  v-model="task.priority"
                  outlined
                  :items="priority"
                  item-value="value"
                  item-text="text"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Заголовок (например:
            <template v-for="(title, index) in titles"><a class="ml-1"
                                                          @click="task.title = title;$forceUpdate();">{{ title }}</a>
              <template v-if="index+1!==titles.length">,</template>
            </template>
            )
          </v-subheader>
          <v-text-field
              v-model="task.title"
              outlined
          />
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Для кого</v-subheader>
          <v-row>
            <v-select
                v-model="task.user_id"
                :items="[{n_id: '-1', login: 'Для всех'}].concat(...this.$store.state.user.managers)"
                item-text="login"
                item-value="n_id"
                outlined/>
          </v-row>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Срок выполнения</v-subheader>
          <v-row>
            <v-col class="pl-0 col-12 col-md-6 overflow-x-auto flex-nowrap d-flex d-md-block">
              <v-btn style="background: #eee !important;margin: 5px 5px 0 0"
                     v-for="time in [15*60, 3600, 2*3600, 24*3600]" :key="time"
                     @click="setDatetimeTo(time)">
                <template v-if="time<3600">через {{ time / 60 }} мин</template>
                <template v-else-if="time<3600*24">через {{ time / 3600 }} ч</template>
                <template v-else>через {{ time / 3600 / 24 }} д</template>
              </v-btn>
            </v-col>
            <v-col class="col-6 col-md-2">
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      :value="$root.dateToRus(task.datetime_toObj.date)"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  />
                </template>
                <v-date-picker
                    v-model="task.datetime_toObj.date"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    locale="ru"
                    @input="menu = false"
                />
              </v-menu>
            </v-col>
            <v-col class="col-6 col-md-2">
              <v-text-field
                  v-model="task.datetime_toObj.time"
                  v-mask="'##:##'"
                  outlined
              />
            </v-col>
          </v-row>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Текст</v-subheader>
          <v-textarea
              v-model="task.text"
              outlined
              rows="4"
          />
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Клиент</v-subheader>
          <v-autocomplete
              :loading="loading_phones"
              v-model="task.client_id"
              :items="phones"
              hide-no-data
              :search-input.sync="searchPhones"
              outlined
              :no-filter="true"
              item-value="n_id"
              item-text="phone"
          />
        </v-row>
        <v-row class="row-d-block" v-if="Number(task.order_id)">
          <v-subheader>Прикрепленный заказ</v-subheader>
          <router-link :to="`/orders/${task.order_id}`">
            <span v-if="task.order">
              {{ $root.dateToRus(task.order.datetimecleanObj.date) }} {{
                task.order.datetimecleanObj.time
              }}, {{ task.order.address.street }} д{{ task.order.address.house }}
            </span>
            <span v-else>
              Открыть
            </span>
          </router-link>
        </v-row>
        <v-row style="" v-for="(notifyField, index) in ['notify1Obj','notify2Obj','notify3Obj']" :key="notifyField"
               class="row-d-block">
          <v-subheader>Напоминание {{ index + 1 }}</v-subheader>
          <v-row>
            <v-col class="pl-0 col-12 col-md-6 overflow-x-auto flex-nowrap d-flex d-md-block">
              <v-btn style="background: #eee !important;margin: 5px 5px 0 0"
                     v-for="time in [15*60, 30*60, 3600, 2*3600, 24*3600]" :key="time"
                     @click="setNotification(notifyField, time)">
                <template v-if="time<3600">за {{ time / 60 }} мин</template>
                <template v-else-if="time<3600*24">за {{ time / 3600 }} ч</template>
                <template v-else>за {{ time / 3600 / 24 }} д</template>
              </v-btn>
            </v-col>
            <v-col class="col-6 col-md-2">
              <v-menu
                  ref="menu"
                  v-model="menu2[index]"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      :value="$root.dateToRus(task[notifyField].date)"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  />
                </template>
                <v-date-picker
                    v-model="task[notifyField].date"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    locale="ru"
                    @input="menu2[index] = false"
                />
              </v-menu>
            </v-col>
            <v-col class="col-6 col-md-2">
              <v-text-field
                  v-model="task[notifyField].time"
                  v-mask="'##:##'"
                  outlined
              />
            </v-col>
          </v-row>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Документы</v-subheader>
          <v-file-input
              ref="file_input"
              style="display: none"
              v-model="task.doc"
              @change="setDoc"
              prepend-icon="mdi-paperclip"
              outlined
              placeholder="Выберите документ"
              hide-input
          />
        </v-row>
        <v-row v-if="task.documents" v-for="(doc, index) in task.documents" :key="index">
                <span>
                  <a :href="doc.url" :download="doc.name">{{ doc.name }}</a>
                  <v-icon dense @click="deleteDoc(index)">mdi-close</v-icon>
                </span>
        </v-row>
        <v-btn @click="$refs.file_input.$refs.input.click()">+ Добавить файл</v-btn>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="editTask" :loading="loading_save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Edit",
  data() {
    return {
      opened: false,
      task: {
        datetime_toObj: {},
        notify1Obj: {},
        notify2Obj: {},
        notify3Obj: {},
        id: null,
        priority: '0',
        documents: [],
        user_id: '-1'
      },
      loading_phones: false,
      phones: [],
      searchPhones: null,
      loading_save: false,
      priority: [
        {value: '0', text: 'Низкий'},
        {value: '1', text: 'Средний'},
        {value: '2', text: 'Высокий'},
      ],
      menu: false,
      menu2: [0, 0, 0],
      managers: [
        {n_id: '-1', fname: 'Для всех'},
      ],
      order_id: null,
      titles: ['Перезвонить позже']
    }
  },
  methods: {
    open(task_id, order_id) {
      this.task = {
        datetime_toObj: {},
        notify1Obj: {},
        notify2Obj: {},
        notify3Obj: {},
        id: task_id,
        order_id: Number(order_id) || 0,
        priority: '0',
        user_id: '-1'
      };
      if (this.task.id) this.getTask()
      else this.setDatetimeTo(15 * 60);
      console.log(this.$store.state.user.managers)
      this.opened = true;
    },
    close(clearQueryID = true) {
      this.opened = false;
      this.task = {
        datetime_toObj: {},
        notify1Obj: {},
        notify2Obj: {},
        notify3Obj: {},
        id: task_id,
        order_id: Number(order_id) || 0,
        priority: '0',
        user_id: '-1'
      };
      if (this.$route.query.task && clearQueryID) this.$router.push({query: {}});
    },
    onToggle() {
      if (this.$route.query.task) this.$router.push({query: {}})
      // this.managers = [{n_id: '-1', fname: 'Всем'}]
    },
    deleteDoc(key) {
      if (!confirm('Удалить документ?')) return;
      delete this.task.documents[key]
      this.task.documents = this.task.documents.filter(i => i);
      this.$forceUpdate()
    },
    setDoc() {
      let file = this.task.doc;
      const formData = new FormData()
      formData.append('file', file, file.name)
      this.axios.post('https://cleanbros.ru/upload', formData)
          .then(data => {
            this.task.doc = null;
            this.task.documents.push({url: data.data.response, name: file.name});
            console.log(this.task.documents)
            this.$root.notify('Документ добавлен', 'success')
          })
          .catch(e => {
            this.$root.notify(e, 'error')
          })
    },
    getTask() {
      this.$store.state.server.request(`task/get/${this.task.id}`, {}, (data) => {
        try {
          data.response.documents = data.response.documents ? JSON.parse(data.response.documents) : [];
        } catch (e) {
        }
        if (!Array.isArray(data.response.documents)) data.response.documents = []
        this.task = data.response;
        console.log(this.task);
      })
    },
    setDone() {
      if (this.task.status == 1) this.editTask();
    },
    editTask() {
      this.loading_save = true;
      let params = {...this.task};
      params.documents = JSON.stringify(params.documents)
      this.$store.state.server.request(this.task.id ? `task/update/${this.task.id}` : 'task/create', params, () => {
        this.loading_save = false;
        this.$root.notify('Задача сохранена')
        this.opened = false;
        this.$eventBus.$emit('editTask')
      }, (data) => {
        this.loading_save = false;
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },
    findByPhone(tel) {
      this.loading_phones = true
      this.$store.state.server.request('client/find', {phone: tel}, (data) => {
        this.phones = data.response;
        this.phones.forEach(el => {
          el.phone_name = el.phone + ' ' + el.name;
        })
        this.loading_phones = false
      })
    },
    setNotification(field, time) {
      let date = new Date(`${this.task.datetime_toObj.date} ${this.task.datetime_toObj.time}`);
      date.setTime(date.getTime() - time * 1000);
      this.task[field] = {
        date: this.$moment(date).format('YYYY-MM-DD'),
        time: this.$moment(date).format('HH:mm'),
      };
    },
    setDatetimeTo(time) {
      let date = new Date();
      date.setTime(date.getTime() + time * 1000);
      this.task.datetime_toObj = {
        date: this.$moment(date).format('YYYY-MM-DD'),
        time: this.$moment(date).format('HH:mm'),
      };
    },
    changeStatus() {
      this.task.status = this.task.status === '0' ? '1' : '0';
    }
  },
  watch: {
    searchPhones(val) {
      val && this.findByPhone(val)
    },
  }
}
</script>

<style scoped>

</style>