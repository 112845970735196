<template>
  <v-dialog data-app v-model="opened">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Уведомления</v-card-title>
      <v-card-text>
        <v-list three-line>
          <v-list-item
              v-for="notification in allNotifications"
              :key="notification.id"
          >
            <v-list-item-content>
              <v-list-item-title>{{notification.title}}</v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Notifications",
  data() {
    return {
      opened: false,
      allNotifications: [],
    }
  },
  methods: {
    open() {
      this.opened = true;
      this.getAllNotifications()
    },
    getAllNotifications() {
      this.$store.state.server.request('user/notifications', {all: 1}, (data)=>{
        console.log('All notif', data.response)
        this.allNotifications = data.response;
        this.seenAll()
      }, (data)=>{
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },
    seenAll() {
      this.$store.state.server.request('user/seen', {last_id: this.$store.state.lastID})
    }

  }
}
</script>

<style scoped>

</style>