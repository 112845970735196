<template>
  <div v-if="src" style="z-index: 10000;background: white;height: 50px">
    <audio autoplay style="width: 100%;" controls :src="src"></audio>
    <v-icon @click="src=''" style="position: absolute;right: 5px;bottom: 40px;background: white;border-radius: 50px">mdi-close</v-icon>
  </div>
</template>

<script>
export default {
  name: 'Player',
  data() {
    return {src: ''}
  },
  mounted() {
    this.$eventBus.$on('playAudio', (src) => {
      this.src = src;
    })
  },
  watch: {
    '$store.state.player_src': () => {
      console.log('start player');
    }
  }
}
</script>
<style scoped>
audio::-webkit-media-controls-panel {
  padding: 20px 10px;
  background: white;
}
</style>