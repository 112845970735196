<template>
  <v-dialog data-app v-model="opened" max-width="500px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title v-if="client.phone">СМС клиенту {{ client.name }} {{ client.phone }}</v-card-title>
      <template v-else>
        <v-card-title>СМС клиентам:</v-card-title>
        <ClientLink style="display: block;width: fit-content;margin-left: 20px" :value="client" v-for="client in clients"></ClientLink>
      </template>
      <v-card-text>
        <v-form
            ref="form">
          <v-row>
            <v-textarea
                v-model="text"
                outlined
                rows="4"
                placeholder="Текст SMS"
            />
          </v-row>
          <div>{{ text.length }} {{ $root.numberWord(text.length, ['символ', 'символа', 'символов']) }},
            {{ Math.ceil(text.length / 67) }} смс
          </div>
          <v-row v-if="error" class="error-text-modal">
            <v-subheader>{{ error }}</v-subheader>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="send" :loading="loading_send">Отправить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ClientLink from "../../../components/ClientLink";

export default {
  name: "Sms",
  components: {ClientLink},
  data() {
    return {
      opened: false,
      text: '',
      loading_send: false,
      error: null,
      onSuccess: null,
      client: {},
      clients: [],
    }
  },
  methods: {
    open(client, text, onSuccess) {
      this.opened = true;
      this.clients = [];
      this.client = {};
      if (Array.isArray(client)) this.clients = client;
      else this.client = client;
      this.onSuccess = onSuccess;
      this.text = text || '';
      console.log(this.client, this.clients);
    },
    send() {
      this.$store.state.server.request(`client/sendSMS/${this.client.n_id || 0}`, {
        text: this.text,
        phone: this.client.phone || this.clients.map(client => client.phone)
      }, (data) => {
        this.$root.notify(data.response, 'success');
        this.loading_send = false;
        if (this.onSuccess) this.onSuccess(data);
        this.opened = false;
      }, (data) => {
        this.error = data.error
        this.loading_send = false;
      })
    }
  }
}
</script>

<style scoped>

</style>