<template>
  <v-dialog @input="onToggle()" data-app v-model="opened">
    <v-container>
      <v-icon class="btn-close-modal" @click="close()" size="36">mdi-close</v-icon>
      <v-row>
        <v-col cols="5" max-height="400px" class="mobile-col-basis">
          <v-card height="auto" style="align-self: baseline">
            <v-card-title style="word-break: break-word">{{ client.name }}</v-card-title>
            <v-card-actions class="py-0">
              <v-btn
                  @click="$eventBus.$emit('sendSMS', client, `${client.name}, мы не смогли с вами связаться для уточнения вашего заказа на уборку. Перезвоните нам:${$store.state.user.city.phone}. Ежедневно с ${$store.state.user.city.worktime_from} до ${$store.state.user.city.worktime_to}`)">
                Отправить SMS
              </v-btn>
              <v-btn @click="$router.push('/create/'+client.phone)">Создать заказ</v-btn>
            </v-card-actions>
            <v-card-text>
              Отправить промо:
              <v-btn class="mr-1" v-for="i in 3" @click="sendPromo($store.state.user.price['client_promo'+i])">
                {{ $store.state.user.price['client_promo' + i] + $store.state.user.city.country.currency }}
              </v-btn>
              <v-row>
                <v-subheader>Важный</v-subheader>
                <v-checkbox
                    v-model="client.important"
                    true-value="1"
                    false-value="0"/>
              </v-row>
              <v-row>
                <v-subheader>SMS</v-subheader>
                <v-checkbox
                    v-model="client.sendSMS"
                    true-value="1"
                    false-value="0"/>
              </v-row>
              <v-row>
                <v-subheader>Без обзвона</v-subheader>
                <v-checkbox
                    v-model="client.no_feedback"
                    true-value="1"
                    false-value="0"/>
              </v-row>
              <v-row>
                <v-subheader>Черный список</v-subheader>
                <v-checkbox
                    v-model="client.black_list"
                    true-value="1"
                    false-value="0"/>
              </v-row>
              <!--              <v-row>-->
              <!--                <v-col cols="3">-->
              <!--                  <v-subheader>Средняя оценка</v-subheader>-->
              <!--                </v-col>-->
              <!--                <v-col cols="9">-->
              <!--                  <v-text-field-->
              <!--                    outlined-->
              <!--                    v-model="client.avrgRate"></v-text-field>-->
              <!--                </v-col>-->
              <!--              </v-row>-->
              <v-row class="row-d-block">
                <v-subheader>Тел.</v-subheader>
                <v-row>
                  <v-text-field
                      outlined
                      v-mask="$store.state.setMask(client.phone)"
                      v-model="client.phone"/>
                  <div style="cursor: pointer;padding: 5px 15px;"
                       :disabled="!client.phone">
                    <a :href="'tel: ' + client.phone">Позвонить</a>
                  </div>
                </v-row>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Имя</v-subheader>
                <v-text-field
                    outlined
                    v-model="client.name"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Email</v-subheader>
                <v-text-field
                    outlined
                    v-model="client.email"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Язык</v-subheader>
                <v-select
                    v-model="client.lang"
                    dense
                    :items="$store.state.toSelectItems($store.state.user.languages ? $store.state.user.languages : [], 'title', 'code')"
                    outlined
                />
              </v-row>
              <v-row class="row-d-block" v-if="$store.state.user.city">
                <v-subheader>Способ отправки системных сообщений
                  <InfoTip value="Работает при настроенной интеграции с Salebot"/>
                </v-subheader>
                <v-select
                    v-model="client.notification_method"
                    dense
                    :items="$store.state.toSelectItems({'':'Последнее место переписки или СМС', 'sms':'СМС (принудительно)', 'whatsapp':'WhatsApp (принудительно)'})"
                    outlined
                />
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Бонусы</v-subheader>
                <v-text-field
                    outlined
                    disabled
                    style="width: 70px;display: inline-flex;"
                    v-model="client.bonus"/>
                <v-btn class="ml-2 v-btn-transp" @click="$eventBus.$emit('openAddClientBonus', client.n_id)"
                       style="color: green!important;" small>+ Начислить
                </v-btn>
                <v-btn class="ml-2 v-btn-transp" @click="$eventBus.$emit('openAddClientBonus', client.n_id, false)"
                       style="color: red!important;" small>- Списать
                </v-btn>
                <v-btn @click="showBonusHistory = !showBonusHistory"
                       :class="showBonusHistory ? 'v-btn-info' : 'v-btn-transp'" class="ml-2" small>История
                </v-btn>
                <v-simple-table v-if="showBonusHistory">
                  <thead>
                  <tr>
                    <th>Дата</th>
                    <th>Сумма</th>
                    <th>Коммент</th>
                    <th>Менеджер/заказ</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="row in client.bonusList" :key="row.id">
                    <td>{{ $root.dateToRus(row.datetime_createObj.date) }} {{ row.datetime_createObj.time }}</td>
                    <td style="white-space: nowrap">
                      {{ $root.printCost(row.sum) }}
                      <span style="color: #3399ff" v-if="+row.cashback_percent">{{ row.cashback_percent }}%</span>
                      <div v-if="row.order">Заказ на {{ $root.printCost(row.order.totalPay) }}</div>
                    </td>
                    <td>{{ row.comment }} <br v-if="row.comment">{{ row.comment_client }}</td>
                    <td>
                      <OrderLink v-if="row.order" :value="row.order"/>
                      <span v-else>{{ row.user_login || '' }}</span>
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>О клиенте</v-subheader>
                <v-textarea
                    outlined
                    v-model="client.text"
                    rows="3"/>
              </v-row>
              <v-row style="display: flex; align-items: center">
                <v-subheader>Всего заказов:</v-subheader>
                <b class="ml-2">{{
                    client.history && client.history.orders ? (client.history.orders.length >= 100 ? ' более ' + client.history.orders.length : client.history.orders.length) : ''
                  }}</b>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn :loading="loading_save" @click="updateClient(); loading_save = !loading_save">Сохранить</v-btn>
            </v-card-actions>
          </v-card>
          <v-row>
            <v-card>
              <v-card-title>Карты</v-card-title>
              <v-card-text>
                <v-data-table
                    :headers="headersCard"
                    :items="client.cards"
                    item-key="n_id"
                    :footer-props="{'items-per-page-options':[-1]}"
                    no-data-text="Карт нет"
                    loading-text="Загрузка..."
                    mobile-breakpoint="0">
                  <template v-slot:item="{item}">
                    <tr>
                      <td>
                        <v-btn x-small @click="removeCard(item.CardId)">Отвязать</v-btn>
                        {{ item.CardName }}
                      </td>
                      <td>{{ item.CardHolder }}</td>
                      <td>{{ item.type }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-row>
          <v-row>
            <v-card>
              <v-card-title>Промокоды</v-card-title>
              <v-card-text>
                <v-text-field
                    v-model="searchPromo"
                    placeholder="Начните вводить промокод..."
                    label=""
                    append-icon="mdi-magnify"
                ></v-text-field>
                <v-data-table
                    :search="searchPromo"
                    :headers="headersPromo"
                    :items="client.promos"
                    item-key="n_id"
                    :footer-props="{'items-per-page-options': [10]}"
                    no-data-text="Промокодов нет"
                    loading-text="Загрузка..."
                    mobile-breakpoint="0">
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-row>
          <!--              <v-row>-->
          <!--                <v-col cols="3">-->
          <!--                  <v-subheader>На сумму</v-subheader>-->
          <!--                </v-col>-->
          <!--                <v-col cols="9">-->
          <!--                  <strong></strong>-->
          <!--                </v-col>-->
          <!--              </v-row>-->
          <!--              <v-row>-->
          <!--                <v-col cols="3">-->
          <!--                  <v-subheader>Средний чек</v-subheader>-->
          <!--                </v-col>-->
          <!--                <v-col cols="9">-->
          <!--                  <strong></strong>-->
          <!--                </v-col>-->
          <!--              </v-row>-->

        </v-col>
        <v-col cols="7" class="mobile-col-basis">
          <v-card height="auto">
            <v-card-title>Заказы</v-card-title>
            <v-card-text>
              <v-data-table
                  :headers="headerOrders"
                  :items="client.history ? client.history.orders : ''"
                  item-key="id"
                  :loading="loading"
                  :footer-props="{'items-per-page-options':[20]}"
                  no-data-text="Заказов нет"
                  loading-text="Загрузка..."
                  mobile-breakpoint="0"
              >
                <template v-slot:item="{item}">
                  <tr @click="editOrder(item.n_id)">
                    <td>
                      <div style="display: flex;align-items: center;justify-content: space-between;gap: 10px">
                        <router-link :to="`/orders/${item.n_id}`">
                          {{ item.n_id }}
                        </router-link>
                        <router-link :to="`/orders/${item.n_id}?copyOrder=1`" target="_blank">
                          <IconTip title="Создать копию заказа" icon="content-copy"
                                   iconStyle="cursor: pointer" size="16"/>

                        </router-link>
                      </div>
                    </td>
                    <td>
                      <router-link :to="`/orders/${item.n_id}`">
                        <template v-if="item.feedback">
                          {{ item.feedback }}
                          <v-icon size="16" color="yellow">mdi-star</v-icon>
                        </template>
                        {{ $store.state.cleanTypes[item.type] }}
                      </router-link>
                    </td>
                    <td>{{ item.options }}</td>
                    <td>{{ $root.dateToRus(item.datetimecleanObj.date) }} {{ item.datetimecleanObj.time }}</td>
                    <td>{{ item.address.street }}, {{ item.address.house }}</td>
                    <td>{{ item.orderStatusString }}</td>
                    <td>
                      <a class="cleaner" v-for="cleaner in item.cleaners" :key="cleaner.n_id"
                         v-on:click.stop="$eventBus.$emit('openCleaner', cleaner.n_id)" style="cursor: pointer;">{{
                          cleaner.name
                        }}</a>
                    </td>
                    <td>{{ item.manager.fname }}</td>
                    <td>{{ item.total }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-card height="auto">
            <v-card-title>Адреса</v-card-title>
            <v-card-text>
              <v-data-table
                  :headers="[
        {value: 'street', text: 'Адрес'},
        {value: 'avg_time', text: 'Статистика (быстрые)'},
        {value: '', text: ''},
      ]"
                  :items="client.addresses"
                  item-key="n_id"
                  :footer-props="{'items-per-page-options':[-1]}"
                  no-data-text="Адресов нет"
                  loading-text="Загрузка..."
                  mobile-breakpoint="0">
                <template v-slot:item="{item, index}">
                  <tr :style="item.deleted*1?'opacity: 0.6':''">
                    <td style="width: 40%">
                      <Address :value="item"></Address>
                    </td>
                    <td>
                      <IconTip v-if="item.stats.housemap" color="green" title="Есть карта дома"
                               icon="map-check-outline"></IconTip>
                      {{ item.stats.count }} шт,
                      {{ item.stats.duration.avg_str }}
                      <InfoTip v-if="item.stats.duration.avg" value="Среднее время уборки быстрой уборки"></InfoTip>
                    </td>
                    <td>
                      <v-btn @click="updateAddress(item.n_id, index, {deleted:0})" v-if="item.deleted*1" small>
                        <v-icon size="16">mdi-refresh</v-icon>
                        Вернуть
                      </v-btn>
                      <v-btn @click="updateAddress(item.n_id, index, {deleted:1})" v-else small
                             style="background-color: red !important;color: white !important;">
                        <v-icon size="16">mdi-delete-outline</v-icon>
                        Удалить
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-card height="fit-content">
            <v-card-text>
              <ChatContainer v-if="client.history.actions" :item="{client}" ref="chat_container" :show-textarea="true"
              message-request-method="client"/>
            </v-card-text>
          </v-card>
          <!--          <ClientActions :actions="client.history.actions"/>-->
        </v-col>
      </v-row>
      <v-row>
        <v-col>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import {Server} from "@/api/server";
import Sms from "@/views/orders/modals/Sms";
import Address from "../../components/Address";
import InfoTip from "../../components/InfoTip";
import IconTip from "../../components/IconTip";
import OrderLink from "../../components/OrderLink";
import ClientActions from "./ClientActions";
import ChatContainer from "@/views/leads/components/ChatContainer";

const server = new Server();

export default {
  name: "User",
  components: {
    ChatContainer,
    ClientActions,
    OrderLink,
    IconTip,
    InfoTip,
    Address,
    Sms,
  },
  data() {
    return {
      loading: false,
      loading_save: false,
      searchPromo: '',
      id: 0,
      opened: false,
      showBonusHistory: false,
      user_phone: null,
      client: {history: {}},
      headersCard: [
        {value: 'CardName', text: 'Название карты'},
        {value: 'CardHolder', text: 'Владелец'},
        {value: 'type', text: 'Тип'},
      ],
      headersPromo: [
        {value: 'code', text: 'Код'},
        {value: 'title', text: 'Название'},
        {value: 'dateFromTo', text: 'Срок действия'},
      ],
      dateFromTo: null,
      headerOrders: [
        {value: 'n_id', text: '#'},
        {value: 'type', text: 'Тип'},
        {value: 'options', text: 'Опции'},
        {value: 'datetimecleanObj', text: 'Дата уборки'},
        {value: 'address', text: 'Адрес'},
        {value: 'orderStatusString', text: 'Статус'},
        {value: 'cleaners', text: 'Клинеры'},
        {value: 'manager', text: 'Оформил'},
        {value: 'total', text: 'Сумма'},
      ]
    }
  },
  methods: {
    close(clearQueryID = true) {
      this.opened = false;
      this.client = {history: {}};
      if (this.$route.query.client && clearQueryID) this.$router.push({query: {}});
    },
    onToggle() {
      this.client = {history: {}};
      if (this.$route.query.client) this.$router.push({query: {}})
    },
    updateAddress(id, key, params) {
      this.$store.state.server.request('client/updateAddress', {id, params}, (data) => {
        this.$root.notify('Адрес изменен');
        this.client.addresses[key] = data.response;
        this.$forceUpdate();
      })
    },
    open() {
      this.opened = true;
      console.log('id ' + this.id);
      this.client = {history: {}};
      this.getUser()
    },
    getUser() {
      server.request(`client/get/${this.id}`, {}, (data) => {
        this.client = data.response;
        this.getClientCards()
        this.client.promos.forEach((el) => {
          el.dateFromTo = `${this.$root.dateToRus(el.datefrom)} - ${this.$root.dateToRus(el.dateto)}`
        })
        setTimeout(() => {
          this.$refs.chat_container.scrollToEnd(false, true);
        }, 100);
        console.log('get user', data)
      })
    },
    getClientCards() {
      this.$store.state.server.request(`client/cards/${this.id}`, {}, (data) => {
        this.client.cards = data.response
      })
    },
    removeCard(card) {
      if (!confirm('Отвязать карту клиента?')) return;
      this.$store.state.server.request(`client/removeCard/${this.id}`, {card}, (data) => {
        this.getClientCards();
      })
    },
    formatName(name) {
      if (!name) return '';
      name = name.trim();
      name = name.split(' ');
      name = name.filter(i => i);
      if (!name.length) return '';
      if (name.length === 1) return (name[0][0] + name[0][1]).toUpperCase();
      return (name[0][0] + name[1][0]).toUpperCase();
    },
    sendPromo(sum) {
      if (!confirm('Отправить промо?')) return;
      this.$store.state.server.request(`client/sendPromo/${this.id}`, {sum}, (data) => {
        this.$root.notify('Промокод отправлен', 'success');
      }, (data) => {
        this.$root.notify(data && data.error ? data.error : 'Не получилось отправить промокод', 'error');
      });
    },
    updateClient() {
      let params = {};
      let fields = ['sendSMS', 'important', 'text', 'email', 'phone', 'bonus', 'no_feedback', 'black_list', 'name', 'notification_method', 'lang'];
      for (let i in fields) params[fields[i]] = this.client[fields[i]];
      this.$store.state.server.request(`client/update/${this.id}`, params, (data) => {
        this.client = data.response
        this.$root.notify('Клиент сохранен', 'success')
        this.loading_save = false;
      }, (data) => {
        if (data && data.error) this.$root.notify(data.error, 'error')
        this.loading_save = false;

      })
    },
    editOrder(id) {
      this.$router.push(`/orders/${id}`);
    },
  },
  mounted() {
    this.$eventBus.$on('clientBonusChanged', (bonus) => {
      this.client.bonus = bonus;
    })
  },
}

</script>

<style scoped>

</style>
