<template>
  <v-menu
      transition="slide-y-transition"
      content-class="header-dropdown-menu"
      bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-list-item-title
          style="font-size: 14px !important; overflow: visible;display: flex;height: 100%;align-items: center;"
          v-bind="attrs"
          class="mr-2"
          v-on="on">
        {{ $store.state.user.city ? $store.state.user.city.city : '' }}
        <v-icon small style="transform: rotate(-30deg)">mdi-navigation-outline</v-icon>
      </v-list-item-title>
    </template>
    <v-list style="max-height: 60vh;padding: 0">
      <v-list-item
          v-for="city in $store.state.user.cities" style="min-height: 40px" :key="city.id" @click="selectCity(city)">
        <v-list-item-title style="font-size: 14px !important;">{{ city.city }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "TheHeaderDropdownCities",
  data() {
    return {}
  },
  methods: {
    selectCity(city) {
      let countryCode = (this.$store.state.user.city.country.id==1 ? '':(location.pathname.split('/')[1]+'/'));
      let cityCode = (this.$store.state.user.city.country.main_city==city.code?'':city.code);
      // console.log({countryCode, cityCode}, this.$store.state.user.city.country.main_city, city.country)
      location.href = `/${countryCode}${cityCode}` + location.hash.split('?')[0];
    }
  }
}
</script>

<style scoped>

</style>
