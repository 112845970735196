<template>
  <v-dialog data-app v-model="opened">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="n_id"
          sort-by="orders.length"
          sort-desc
          :footer-props="{'items-per-page-options':[-1]}"
          no-data-text="Данных нет"
          loading-text="Загрузка..."
          mobile-breakpoint="0">
          <template v-slot:item.orders_list="{item}">
            <div class="my-1" v-for="order in item.orders">
              на {{ order.lateness }} мин, заказ:
              <OrderLink :value="order.item"/>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import OrderLink from "../../components/OrderLink";

export default {
  name: "CleanerLateness",
  components: {OrderLink},
  data() {
    return {
      opened: false,
      headers: [
        {value: 'cleaner', text: 'Клинер'},
        {value: 'orders.length', text: 'Кол-во опозданий'},
        {value: 'orders_list', text: 'Заказы'},
      ],
      items: [],
    }
  },
  methods: {
    open(items) {
      let cleaners = {};
      items.forEach(item => {
        if (!cleaners.hasOwnProperty(item.cleaner.n_id)) {
          cleaners[item.cleaner.n_id] = {
            orders: [],
            cleaner: this.$root.cleanerInitials(item.cleaner.name),
          }
        }
        item.order.address = item.address;
        cleaners[item.cleaner.n_id].orders.push({
          item: item.order,
          lateness: parseInt((item.datetime - item.order.datetimeclean) / 60)
        })
      })
      this.items = Object.values(cleaners);
      console.log(this.items);
      this.opened = true;
    },
  }
}
</script>

<style scoped>

</style>