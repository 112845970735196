<template>
  <v-dialog data-app v-model="opened" @input="onToggle()" max-width="500px">
    <v-card>
      <v-icon class="btn-close-modal" @click="close()" size="36">mdi-close</v-icon>
      <v-card-title style="word-break: break-word">Отправка пуш-уведомлений клинерам</v-card-title>
      <v-card-text>
        <v-form
            ref="form">
          <v-row>
            <v-subheader>Кому: {{ item.name }}</v-subheader>
          </v-row>
          <v-row>
            <v-textarea
                v-model="text"
                outlined
                rows="4"
                placeholder="Сообщение"
            />
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="send" :loading="loading">Отправить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PushToWorkers",
  data() {
    return {
      opened: false,
      loading: false,
      item: {},
      text: '',
    }
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
      this.text = ''
      this.item = {};
    },
    onToggle() {
      console.log('onToggle');
      this.text = ''
      this.item = {}
    },
    send() {
      this.loading = true;
      this.$store.state.server.request(`cleaner/sendNotifications`, {id: this.item.n_id, text: this.text}, (data) => {
        this.$root.notify('Уведомления успешно отправлены');
        this.loading = false;
        this.close();
      }, (data) => {
        this.$root.notify(data.error || 'Не получилось отправить уведомления', 'error');
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>