<template>
  <v-menu
      style="background: #ffffff; top: 44px!important;"
      transition="slide-y-transition"
      content-class="header-dropdown-menu"
      bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-list-item-title
          style="font-size: 14px !important;padding-right: 10px;text-overflow: unset;margin-bottom: -1px;display: flex;height: 100%;align-items: center;"
          v-bind="attrs"
          v-on="on">
        <span class="mobile-d-none">{{$store.state.user.fname}}</span>
        <v-avatar size="28">
          <img src="/img/user.png" alt="">
        </v-avatar>
      </v-list-item-title>
    </template>
    <v-list style="max-height: 175px;padding: 0">
      <v-list-item @click="logOut()" style="min-height: 40px">
        <v-list-item-title  style="font-size: 14px !important;">
          Выйти
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return {
      itemsCount: 42
    }
  },
  methods: {
    logOut() {
      this.$store.state.server.logout();
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>