<template>
  <v-app data-app class="c-app">
    <v-dialog
        v-model="isIdle"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Вас давно не было:(
        </v-card-title>

        <v-card-text style="margin-top: 20px">
          Пока вас нет, работа црм приостановлена.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
          >
            Возобновить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="$vuetify.breakpoint.width < 480 && $store.state.sidebarShow === 'responsive'"
               class="container-overlay"
               @click.native="$store.commit('toggleSidebarMobile')"/>
    <TheSidebar/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body" ref="body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <flash-message transitionIn="animated swing" class="flashpool"></flash-message>
      <v-list class="flashpool" v-if="Object.values($store.state.notifications).length"
              style="top: 140px !important; background: transparent;padding: 0">
        <v-list-item
            v-for="notification in Object.values($store.state.notifications).sort((a,b)=>b.id - a.id)"
            v-if="!parseInt(notification.datetime_seen) && !notification.hidden"
            style="width: 240px; background: #fff;padding:0;"
            :key="notification.id"
            class="mb-4"
        >
          <v-icon size="20" style="position: absolute; top: 0; right: 0"
                  @click="$store.commit('setSeen', [notification, 'hide'])">mdi-close
          </v-icon>
          <Notification :notification="notification"/>
        </v-list-item>
      </v-list>
      <Player></Player>
    </CWrapper>
    <Notifications ref="notifications"/>
    <PushToWorkers ref="PushToWorkers"/>
    <Cleaner ref="Cleaner"/>
    <Client ref="Client"/>
    <Task ref="Task"/>
    <LeadEdit ref="Lead"/>
    <Sms ref="Sms"/>
    <AddClientBonus ref="AddClientBonus"/>
    <TableModal ref="TableModal"/>
    <Alert ref="AlertModal"/>
    <CleanerLateness ref="CleanerLateness"/>
    <CleanersList ref="CleanersList"/>
  </v-app>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import Notifications from "@/views/notifications/Notifications";
import Notification from "@/views/notifications/Notification";
import PushToWorkers from './Modals/PushToWorkers';
import Cleaner from './Modals/Cleaner';
import Client from './Modals/Client';
import Task from './Modals/Task';
import Player from "./Player";
import LeadEdit from "./Modals/Lead";
import Sms from "../views/orders/modals/Sms";
import AddClientBonus from "./Modals/AddClientBonus";
import TableModal from "./Modals/TableModal";
import CleanerLateness from "./Modals/CleanerLateness";
import CleanersList from "./Modals/CleanersList";
import Alert from "./Modals/Alert";

export default {
  name: 'TheContainer',
  data() {
    return {isIdle: false}
  },
  components: {
    Alert,
    CleanersList,
    CleanerLateness,
    TableModal,
    AddClientBonus,
    Sms,
    LeadEdit,
    Player,
    PushToWorkers,
    TheSidebar,
    TheHeader,
    Notifications,
    Notification,
    Cleaner,
    Client,
    Task,
  },
  mounted() {
    window.customErrorHandler = (data) => {
      this.$root.logError(data)
      return false;
    }
    this.$refs.body.onscroll = () => {
      this.$eventBus.$emit('scrollBody', this.$refs.body.scrollTop);
    }
    this.$eventBus.$on('openNotificationsModal', (data) => {
      if (data) {
        const modal = this.$refs.notifications;
        modal.open()
      }
    })
    this.$eventBus.$on('sendWorkerNotifications', (data) => {
      if (data) {
        const modal = this.$refs.PushToWorkers;
        modal.item = data;
        modal.open()
      }
    })
    this.$eventBus.$on('openCleaner', (data) => {
      if (data) {
        const modal = this.$refs.Cleaner;
        modal.id = data;
        modal.open()
        this.$router.push({query: {cleaner: data}})
      }
    })

    this.$eventBus.$on('openClient', (data) => {
      if (data) {
        const modal = this.$refs.Client;
        modal.id = data;
        modal.open()
        this.$router.push({query: {client: data}})
      }
    })

    this.$eventBus.$on('openTask', (task) => {
      if (task) {
        const modal = this.$refs.Task;
        for (let i in task) {
          modal.task[i] = task[i]
        }
        modal.open(task.id, task.order_id)
        this.$router.push({query: {task: task.id}})
      }
    })

    this.$eventBus.$on('openLead', (item) => {
      this.$refs.Lead.open(item);
    })

    this.$eventBus.$on('openAddClientBonus', (client_id, add = true) => {
      this.$refs.AddClientBonus.open(client_id, add);
    })

    this.$eventBus.$on('TableModal', (a, b) => this.$refs.TableModal.open(a, b));
    this.$eventBus.$on('AlertModal', this.$refs.AlertModal.open);
    this.$eventBus.$on('CleanerLateness', (a) => this.$refs.CleanerLateness.open(a));
    this.$eventBus.$on('openCleanersList', this.$refs.CleanersList.open);

    this.$eventBus.$on('sendSMS', (client, text, onSuccess) => {
      this.$refs.Sms.open(client, text, onSuccess);
    })

    this.$eventBus.$on('pageChanged', () => {
      if (this.$refs.Client.opened) this.$refs.Client.close(false);
      else if (this.$route.query.client && parseInt(this.$route.query.client)) {
        this.$eventBus.$emit('openClient', this.$route.query.client);
      }
      if (this.$refs.Cleaner.opened) this.$refs.Cleaner.close(false);
      else if (this.$route.query.cleaner && parseInt(this.$route.query.cleaner)) {
        this.$eventBus.$emit('openCleaner', this.$route.query.cleaner);
      }
      if (this.$refs.Task.opened) this.$refs.Task.close(false);
      else if (this.$route.query.task && parseInt(this.$route.query.task)) {
        this.$eventBus.$emit('openTask', {id: this.$route.query.task});
      }
    })
    this.$eventBus.$emit('pageChanged');
  },
  watch: {
    '$store.state.idleVue.isIdle': function (value) {
      this.isIdle = value;
      this.$store.state.server.isIdle = value;
    },
    '$store.state.user': function () {
      console.warn(this.$store.state.user.rights);
      if (this.$store.state.user.login === 'translate') {
        this.$router.push('/translation')
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
