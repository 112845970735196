<template>
  <v-dialog data-app v-model="opened" :persistent="!closable" :overlay-opacity="opacity" max-width="400px">
    <v-card>
      <v-card-title v-text="title"/>
      <v-icon v-if="closable" class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-text v-text="body"/>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return {
      opened: false,
      closable: true,
      opacity: '',
      title: '',
      body: '',
    }
  },
  methods: {
    open(body, title = 'Ошибка', closable = true, opacity = undefined) {
      this.body = body;
      this.closable = closable;
      this.title = title;
      this.opacity = opacity;
      this.opened = true;
    },
  }
}
</script>

<style scoped>

</style>
