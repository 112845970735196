<template>
  <v-dialog data-app v-model="opened" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="n_id"
          :footer-props="{'items-per-page-options':[-1]}"
          no-data-text="Данных нет"
          loading-text="Загрузка..."
          mobile-breakpoint="0"></v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TableModal",
  data() {
    return {
      opened: false,
      headers: [],
      items: [],
    }
  },
  methods: {
    open(headers, items) {
      this.headers = [];
      for (let i in headers) this.headers.push({value: i, text: headers[i]});
      this.items = items;
      this.opened = true;
    },
  }
}
</script>

<style scoped>

</style>