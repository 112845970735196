<template>
  <CHeader fixed with-subheader light class="mobile-header">
    <div style="display: flex;"
         @click="$store.commit('toggleSidebarMobile')">
      <CToggler
          in-header
          class="ml-3 d-lg-none "
          :class="$store.state.sidebarShow === 'responsive' ? 'mobile-d-none' : ''"

      />
      <span v-if="$vuetify.breakpoint.width < 480 && ($store.state.updatingInfo.incoming || $store.state.updatingInfo.confirm || $store.state.updatingInfo.waiting || $store.state.updatingInfo.feedback)"
          class="menu-badge"
            style="position: absolute; left: 44px;min-width: 0; top: 16px; border-radius: 50%; width: 10px; height: 10px"></span>
    </div>
    <CToggler
        in-header
        class="ml-3 d-md-down-none"
        @click="$store.commit('toggleSidebarDesktop')"
    />
    <router-link to="/create">
      <v-btn fab class="navbar_btn">
        <v-icon style="color: white;margin: 0">
          mdi-plus
        </v-icon>
      </v-btn>
    </router-link>
    <CHeaderBrand class="mobile-d-none">
      <CSubheader>
        <CBreadcrumbRouter class="border-0 mb-0"/>
      </CSubheader>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">

    </CHeaderNav>
    <CHeaderNav class="mr-3">
      <TheHeaderDropdownCities/>
      <TheHeaderDropdownNotifications/>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>

  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import TheHeaderDropdownCities from "@/containers/TheHeaderDropdownCities";
import TheHeaderDropdownNotifications from "@/containers/TheHeaderDropdownNotifications";
export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownCities,
    TheHeaderDropdownNotifications
  }
}
</script>
