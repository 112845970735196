<template>
  <v-menu transition="slide-y-transition"
          content-class="header-dropdown-menu"
          bottom
          @input="openNotifications()"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs"
           v-on="on"
           style="position: relative; margin-right: 10px;display: flex;height: 100%;align-items: center;">
        <v-icon size="24">mdi-bell-outline</v-icon>
        <span v-if="$store.state.unseen" class="menu-badge" style="position: absolute; top: 12px; right: -2px">
            {{ $store.state.unseen }}
            <div class="heartbit"></div>
          </span>
      </div>
    </template>
    <v-list style="max-height: 60vh;padding: 0">
      <v-list-item style="border-bottom: 2px solid #eee;padding: 0" v-for="notif in Object.values($store.state.notifications).sort((a,b)=>b.id - a.id)" :key="notif.id">
        <Notification :notification="notif"></Notification>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

import Notification from "../views/notifications/Notification";

export default {
  name: "TheHeaderDropdownNotifications",
  components: {
    Notification
  },
  data() {
    return {}
  },
  methods: {
    openNotifications() {
      // console.log('openNotifications');
      if (this.$store.state.unseen) this.$store.commit('setSeenAll')
      // this.$eventBus.$emit('openNotificationsModal', true)
    },

  }
}
</script>

<style scoped>

</style>